import { Link, graphql } from 'gatsby';
import { Row, Col, Layout, Button } from 'antd';
import PublicLayout from '../components/PublicLayout';
import SEO from '../components/seo';
import ExamsCards from './components/preOcupational/examsCards';
import imageUrlReplace from './components/blog/imageUrlReplace';

const { Content } = Layout;

const jobArea = ({ pageContext }) => {
  const { jobArea, allExams, countryCode } = pageContext;

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador",
    ],
  }];

  const relatedExamsCards = (relatedIcons, relatedExamsName, relatedExamsSlug) => {
    const iconsArr = relatedIcons.split(';');
    const namesArr = relatedExamsName.split(';');
    const slugsArr = relatedExamsSlug.split(';');
    const relatedExamsArr = [];

    for (let i = 0; i < namesArr.length; i++) {
      const icon = iconsArr[i] ? iconsArr[i] : null;
      const name = namesArr[i] ? namesArr[i] : null;
      const slug = slugsArr[i] ? slugsArr[i] : null;
      
      relatedExamsArr.push({
        icon,
        name,
        slug,
      });
    }

    return (
      <Row gutter={[20, 16]} align='stretch'>
      {
        relatedExamsArr && relatedExamsArr.map((item, index) => {
          return (
            <Col xl={8} lg={8} xs={24} key={index}>
              <Link to={`/examenes/${item.slug}/`} className='exam-card-wrapper'>
                <Row justify='center' align='middle'>
                  <Col lg={22} md={2} sm={3} xs={5}>
                    <Row justify='center' align='middle'>
                      <Col className='icon-wrapper'>
                        <span className={item.icon} />
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={24} md={20} sm={19} xs={17} className='text-wrapper'>
                    <p className='mb-0 mt-lg-10'>{item.name}</p>
                  </Col>
                  <Col xs={2} className='chevron-mobile-wrapper'>
                    <span className='icon-system-chevron-right' />
                  </Col>
                </Row>
              </Link>
            </Col>
          )
        })
      }
      </Row>
    );
  };

  const scrollToOtherBatteries = () => {
    const element = document.getElementById('examsCards');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <PublicLayout>
      <SEO
        title={jobArea.meta_tag.metaTitle}
        description={jobArea.meta_tag.metaDescription}
        image={jobArea.fields.image.sourceUrl}
        dataLayer={{
          userId: "",
          pageId: jobArea.id,
          pageType: "rubro",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='job-area-page-container'>
        <Content>
          <Row>
            <Col lg={24} xs={24}>
              <Row align='middle'>
                <Col xxl={21} xl={22} lg={23} xs={24} className='image-content-wrapper mt-lg-80'>
                  <Row justify='space-between' align='stretch'>
                    <Col xl={11} lg={9} xs={24}>
                      <div
                        className='image'
                        style={{ backgroundImage: `url(${imageUrlReplace(jobArea.fields.image.sourceUrl)})`}}
                        title={jobArea.fields.image.altText}
                      />
                    </Col>
                    <Col xl={12} lg={14} xs={24}>
                      <Row justify='center'>
                        <Col lg={24} xs={24} className='main-text-wrapper'>
                          <Row justify='center'>
                            <Col lg={24} md={13} sm={14} xs={20}>
                              <h1>En Centro Médico del trabajador realizamos exámenes asociados a los riesgos de <strong>trabajos en {jobArea.fields.name.toLowerCase()}</strong></h1>
                            </Col>
                            {
                              jobArea.fields.description &&
                              <Col lg={24} md={13} sm={14} xs={20}>
                                <p className='text-description'>{jobArea.fields.description}</p>
                              </Col>
                            }
                          </Row>
                        </Col>
                        <Col lg={24} md={13} sm={14} xs={20}>
                          <p>Algunos de los exámenes sugeridos para este rubro:</p>
                        </Col>
                        <Col lg={24} xs={22}>
                        {
                          relatedExamsCards(
                            jobArea.fields.relatedIcons,
                            jobArea.fields.relatedExamsName,
                            jobArea.fields.relatedExamsSlug
                          )
                        }
                        </Col>
                        <Col lg={24} xs={24}>
                          <Row className='reserve-button-wrapper mt-lg-40 mt-md-20 mt-sm-20 mt-xs-20'>
                            <Col xxl={6} lg={8} md={8} sm={9} xs={14}>
                              <Link to="/app/reservar/">
                                <Button className='cmt-button secondary' type='primary' size='large'>
                                  Agenda aquí
                                </Button>
                              </Link>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={24} xs={24} className='scroll-button-wrapper mt-50 mb-50'>
                  <Row justify='center'>
                    <Col>
                      <span onClick={scrollToOtherBatteries} className='icon-chevron-down-rounded' />
                    </Col>
                  </Row>
                </Col>
                <Col lg={24} xs={24}>
                  <Row justify='center'>
                    <Col xxl={15} xl={19} lg={19} xs={22}>
                      <ExamsCards usedFrom='jobareas' allExams={allExams} countryCode={countryCode} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default jobArea;

export const postQuery = graphql`
  query GET_LOCALES_JOB_AREA($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;